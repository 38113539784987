.App-logo {
    height: 70px;
    border-radius: 6px;
}

.App-title {
    font-size: 1.5em;
    margin-left: 10px;
    margin-bottom: 0;
    text-align: left;
}

.App-header {
    background-color: #222222;
    padding: 12px;
    color: white;
    text-align: center;
}

.form-outline {
    margin-top: 10px;
}

.form-button {
    margin-top: 15px;
}

.clickable:hover {
    cursor: pointer;
}

.dashboard-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.button-container-right {
    display: flex;
    justify-content: flex-end;
    width: 100%; /* Adjust as needed */
}

.button-container-two-sides {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Adjust as needed */
}

.heart-voted {
    cursor: pointer;
    color: red;
}

.heart-not-voted {
    cursor: pointer;
    color: grey
}

.logout-sign {
    color: #0dcaf0;
    cursor: pointer;
}

.page-size-select {
    height: 30px;
    font-size: 14px !important;
    padding: .25rem 2.25rem .25rem .75rem !important;
}